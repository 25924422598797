<template>
	<div class="detailsMass">
		<div class="tabsClass">
			<p v-for="item in tabList" :key="item.val" :class="{ activeClass: checkVal == item.val }" @click="checkTabs(item)">{{ item.label }}</p>
		</div>
		<div class="detailsMass-item" v-if="checkVal == 1">
			<p class="title">基本信息</p>
			<div class="detailsMass-item-iter" v-if="testData.items && testData.items.length > 0">
				<img v-if="testData.imgsUrl" :src="testData.imgsUrl" alt="" />
				<img v-else src="@/assets/imgs/loginImg.png" alt="" />
				<div class="iter clearfix">
					<div
						v-for="(item, index) in testData.items"
						:key="index"
						:class="item.items && item.items.length !== 0 ? 'threeData clearfix' : 'otherData clearfix'"
					>
						<p class="float-l" :style="{ lineHeight: item.height + 'px', height: item.height + 'px' }">{{ item.name }}</p>
						<p class="float-l" v-if="item.value">{{ item.value || '-' }}</p>
						<div class="threeData-item float-l clearfix" v-if="item.items && item.items.length > 0">
							<div v-for="(subItem, subIndex) in item.items" :key="subIndex">
								<p
									class="float-l"
									:style="{
										lineHeight: subItem.height + 'px',
										height: subItem.height + 'px',
									}"
								>
									{{ subItem.name }}
								</p>
								<div class="threeData-item-item float-l" v-if="subItem.items && subItem.items.length != 0">
									<p style="width: 100%" v-for="(child, childIndex) in subItem.items" :key="childIndex">
										{{ child.name }} / 确诊日期：{{ child.value }}
									</p>
								</div>
								<div class="threeData-item-item float-l" v-else>
									<p style="width: 100%">{{ subItem.value || '-' }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="nullData" v-else>暂无数据</div>
		</div>
		<div class="detailsMass-item" v-if="checkVal == 5">
			<p class="title">{{ testData.title || '' }}</p>
			<div class="detailsMass-item-iter" v-if="testData.items && testData.items.length > 0">
				<div class="iter clearfix">
					<div
						v-for="(item, index) in testData.items"
						:key="index"
						:class="!item.items ? 'otherData clearfix' : item.items ? 'threeData clearfix' : ''"
					>
						<p class="float-l" :style="{ lineHeight: item.height + 'px', height: item.height + 'px' }">
							{{ item.title }}
						</p>
						<p class="float-l">{{ item.value && item.value.toString() }}</p>
					</div>
				</div>
			</div>
			<div class="nullData" v-else>暂无数据</div>
		</div>
		<SubCard v-if="checkVal == 2" ref="subCardVue" :checkVal="checkVal"></SubCard>
		<HistoryCard v-if="checkVal == 3" ref="historyCardVue" :pId="$route.query.id" :checkVal="checkVal"></HistoryCard>
		<OtherService v-if="checkVal == 4" ref="otherService" :pId="$route.query.id"></OtherService>
	</div>
</template>

<script>
import SubCard from './subCard.vue';
import HistoryCard from './historyCard.vue';
import OtherService from './otherService.vue';

export default {
	name: 'showDetails',
	data() {
		return {
			tabList: [
				{
					label: '基本信息',
					val: '1',
				},
				{
					label: '健康体检表',
					val: '2',
				},
				{
					label: '重点人群健康管理',
					val: '3',
				},
				{
					label: '其他医疗服务记录',
					val: '4',
				},
				{
					label: '档案信息卡',
					val: '5',
				},
			],
			checkVal: 1,
			testData: {},
			basicInfoData: {
				title: '基本信息',
				items: [
					{
						name: '姓名',
						value: '',
					},
					{
						name: '性别',
						value: '',
					},
					{
						name: '出生日期',
						value: '',
					},
					{
						name: '身份证号',
						value: '',
					},
					{
						name: '工作单位',
						value: '',
					},
					{
						name: '常住类型',
						value: '',
					},
					{
						name: '本人电话',
						value: '',
					},
					// {
					// 	name: '联系人姓名',
					// 	value: '',
					// },
					// {
					// 	name: '联系人电话',
					// 	value: '',
					// },
					{
						name: '民 族',
						value: '',
					},
					{
						name: '血 型',
						value: '',
					},
					{
						name: '文化程度',
						value: '',
					},
					{
						name: '职业',
						value: '',
					},
					{
						name: '户籍地址',
						value: '',
					},
					{
						name: '医疗费用支付方式',
						value: '',
					},
					{
						name: '药物过敏史',
						value: '',
					},
					{
						name: '暴 露 史',
						value: '',
					},
					{
						name: '精 神 病',
						value: '',
					},
					{
						name: '既往史',
						items: [
							{
								name: '疾病',
								items: [],
							},
							{
								name: '手术',
								value: '',
							},
							{
								name: '外伤',
								value: '',
							},
							{
								name: '输血',
								value: '',
							},
						],
					},
					{
						name: '家 族 史',
						items: [],
					},
					{
						name: '遗传病史',
						value: '',
					},
					{
						name: '残疾情况',
						value: '',
					},
					{
						name: '生活环境',
						items: [
							{
								name: '厨房排风设施',
								value: '',
							},
							{
								name: '燃料类型',
								value: '',
							},
							{
								name: '饮水',
								value: '',
							},
							{
								name: '厕所',
								value: '',
							},
							{
								name: '禽畜栏',
								value: '',
							},
						],
					},
				],
			},
		};
	},
	components: {
		HistoryCard,
		SubCard,
		OtherService,
	},
	created() {
		this.checkVal = this.$route.query.type || '1';
		this.getTableDetails();
	},
	methods: {
		// 获取表格详情
		getTableDetails() {
			if (this.checkVal == 1) {
				this.getBasicInfoData();
				return;
			} else if (this.checkVal == 2) {
				let data = {
					type: 'PHEX',
					id: this.$route.query.id,
				};
				this.$nextTick(() => {
					this.$refs.subCardVue.init(data);
				});
				return;
			} else if (this.checkVal == 5) {
				this.getHLTHData();
			}
		},
		// 获取档案信息卡
		getHLTHData() {
			let data = {
				contentCode: 'RHFI',
				personId: this.$route.query.id,
			};
			this.$http
				.get('/diagnosis-api/v1/PersonForms.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection[0] || {};
						if (JSON.stringify(collection) == '{}' || !collection.content || collection.content.length == 0) {
							return (this.testData = {});
						}
						let datas = JSON.parse(collection.content).items[0];
						this.getDetails(datas);
					}
				})
				.catch((e) => {});
		},
		// 获取基本信息数据
		getBasicInfoData() {
			this.$http.get(this.$route.query.link).then((res) => {
				if (res.data && res.data.success) {
					let collection = res.data.collection[0] || {};
					if (!collection || JSON.stringify(collection) == '{}') {
						return (this.testData = {});
					}
					const {
						name,
						genderDictionaryItem,
						birthDate,
						identificationCode,
						workAddress,
						hukou,
						phoneNumber,
						ethnicityDictionaryItem,
						bloodTypeDictionaryItem,
						educationLevelDictionaryItem,
						occupation,
						hukouAddress,
						paymentMethodDictionaryItem,
						afflictions,
						kitchenFacilityDictionaryItem,
						waterDictionaryItem,
						toiletDictionaryItem,
						corralDictionaryItem,
						fuelDictionaryItem,
						// guardianName,
						// guardianPhoneNumber,
						beingPsychosis,
					} = collection;
					let basicInfoData = JSON.parse(JSON.stringify(this.basicInfoData.items));
					console.log(basicInfoData, collection);
					// return;
					basicInfoData[0].value = name || '-';
					basicInfoData[1].value = genderDictionaryItem?.title || '-';
					basicInfoData[2].value = birthDate || '-';
					basicInfoData[3].value = identificationCode || '-';
					basicInfoData[4].value = workAddress || '-';
					basicInfoData[5].value = hukou ? '常驻' : '非常驻';
					basicInfoData[6].value = phoneNumber || '-';
					// basicInfoData[7].value = guardianName || '-';
					// basicInfoData[8].value = guardianPhoneNumber || '-';
					basicInfoData[7].value = ethnicityDictionaryItem?.title || '-';
					basicInfoData[8].value = bloodTypeDictionaryItem?.title || '-';
					basicInfoData[9].value = educationLevelDictionaryItem?.title || '-';
					basicInfoData[10].value = occupation || '-';
					basicInfoData[11].value = hukouAddress || '-';
					basicInfoData[12].value = paymentMethodDictionaryItem?.title || '-';
					basicInfoData[15].value = beingPsychosis ? '有' : '无';
					if (afflictions && afflictions['A'] && afflictions['A'].length > 0) {
						basicInfoData[13].value = afflictions['A'].map((item) => item.diseaseDictionaryItem?.title).join(';') || '-';
					} else {
						basicInfoData[13].value = '-';
					}
					if (afflictions && afflictions['E'] && afflictions['E'].length > 0) {
						basicInfoData[14].value = afflictions['E'].map((item) => item.diseaseDictionaryItem?.title).join(';') || '-';
					} else {
						basicInfoData[14].value = '-';
					}
					basicInfoData[16].items.forEach((item) => {
						if (item.name == '疾病') {
							if (afflictions && afflictions['D'] && afflictions['D'].length > 0) {
								item.items = afflictions['D'].map((item) => {
									return {
										name: item.diseaseDictionaryItem?.title || '-',
										value: item.afflictTime || '-',
									};
								});
							} else {
								item.value = '无';
							}
						}
						if (item.name == '手术') {
							if (afflictions && afflictions['O'] && afflictions['O'].length > 0) {
								item.value = afflictions['O'].map((item) => item.diseaseDictionaryItem?.title).join(';') || '无';
							}
						}
						if (item.name == '外伤') {
							if (afflictions && afflictions['T'] && afflictions['T'].length > 0) {
								item.value = afflictions['T'].map((item) => item.diseaseDictionaryItem?.title).join(';') || '无';
							}
						}
						if (item.name == '输血') {
							if (afflictions && afflictions['B'] && afflictions['B'].length > 0) {
								item.value = afflictions['B'].map((item) => item.diseaseDictionaryItem?.title).join(';') || '无';
							}
						}
					});
					if (afflictions && afflictions['F'] && afflictions['F'].length > 0) {
						basicInfoData[17].items = afflictions['F'].map((item) => {
							return {
								name: item.relativeDictionaryItem?.title || '-',
								value: item.diseaseDictionaryItem?.title || '-',
							};
						});
					} else {
						basicInfoData[17].value = '无';
					}
					if (afflictions && afflictions['G'] && afflictions['G'].length > 0) {
						basicInfoData[18].value = afflictions['G'].map((item) => item.diseaseDictionaryItem?.title).join(';') || '-';
					} else {
						basicInfoData[18].value = '-';
					}
					if (afflictions && afflictions['H'] && afflictions['H'].length > 0) {
						basicInfoData[19].value = afflictions['H'].map((item) => item.diseaseDictionaryItem?.title).join(';') || '-';
					} else {
						basicInfoData[19].value = '-';
					}
					basicInfoData[20].items[0].value = kitchenFacilityDictionaryItem?.title || '-';
					basicInfoData[20].items[1].value = fuelDictionaryItem?.title || '-';
					basicInfoData[20].items[2].value = waterDictionaryItem?.title || '-';
					basicInfoData[20].items[3].value = toiletDictionaryItem?.title || '-';
					basicInfoData[20].items[4].value = corralDictionaryItem?.title || '-';
					let newData = {
						title: '基本信息',
						items: basicInfoData,
						imgsUrl: (collection.attachmentMap && collection.attachmentMap.PHOTO[0].link) || '',
					};
					this.getDetails(newData);
				}
			});
		},
		getDetails(data) {
			let newData = JSON.parse(JSON.stringify(data));
			const singleHeight = 48;
			handleItemHeight(newData);
			function handleItemHeight(objItem) {
				if (!objItem) return 0;
				if (!objItem.items || objItem.items.length === 0) {
					objItem.height = singleHeight;
					return singleHeight;
				}
				objItem.height = 0;
				objItem.items.map((item) => {
					let childHeight = handleItemHeight(item);
					objItem.items.height = childHeight;
					objItem.height += childHeight;
				});
				return objItem.height;
			}
			this.testData = JSON.parse(JSON.stringify(newData));
		},
		checkTabs(item) {
			this.checkVal = item.val;
			this.getTableDetails();
		},
	},
};
</script>

<style lang="scss" scoped>
.detailsMass {
	background-color: #fff;
	border-radius: 2px;
	margin: 16px;
	padding-bottom: 40px;
	.tabsClass {
		display: flex;
		line-height: 42px;
		height: 42px;
		box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset;
		margin-bottom: 32px;
		p {
			padding: 0 40px;
			cursor: pointer;
		}
		.activeClass {
			color: #1db9b1;
			border-top: 3px solid #1db9b1;
			border-right: 1px solid rgba(0, 0, 0, 0.1);
			border-left: 1px solid rgba(0, 0, 0, 0.1);
			background-color: #fff;
			box-sizing: border-box;
			&:first-child {
				border-left: 0;
			}
		}
	}

	&-item {
		padding: 0 40px;
		.headerClass {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.rightContent {
				color: #666666;
				font-size: 12px;
				span:first-child {
					margin-right: 16px;
				}
			}
		}
		.title {
			font-size: 16px;
			color: #333333;
			padding-left: 8px;
			border-left: 3px solid #1db9b1;
			line-height: 16px;
			margin-bottom: 16px;
		}
		&-iter {
			position: relative;
			border-right: 1px solid rgba(214, 217, 224, 0.8);
			border-bottom: 1px solid rgba(214, 217, 224, 0.8);
			img {
				position: absolute;
				right: 0;
				top: 0;
				width: 148px;
				height: 192px;
			}
			.iter {
				width: calc(100% - 164px);
				p {
					color: #333;
					padding-left: 16px;
					line-height: 48px;
					height: 48px;
					border: 1px solid rgba(214, 217, 224, 0.8);
					width: calc(50% - 165px);
					border-bottom: none;
					border-right: none;
					&:nth-child(2n-1) {
						width: 165px;
						background-color: #f4f6fd;
						border-right: none;
					}
				}
				.otherData {
					width: calc(100% + 164px);
					p {
						width: calc(100% - 165px);
						&:first-child {
							width: 165px;
							background-color: #f4f6fd;
						}
					}
				}
				.threeData {
					width: calc(100% + 164px);
					&-item {
						width: calc(100% - 165px);
						p {
							width: calc(50% - 154px);
							background-color: #fff;
						}
						&-item {
							width: calc(100% - calc(50% - 154px));
							p {
								width: 50%;
								background-color: #fff;
							}
							&-item {
								width: 50%;
								p {
									width: 100%;
									background-color: #fff;
								}
							}
						}
					}
				}
			}
		}
		.nullData {
			text-align: center;
			color: #999999;
			font-size: 20px;
			margin-top: 40px;
		}
	}
}
</style>